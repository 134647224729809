<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 13:37:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-15 18:21:26
-->
<template>
  <div class="tasksListPage">
    <div class="pub_search_section">
      <div class="formLine">
        <div class="city_box">
          <i class="el-icon-location"></i> 选择城市: <el-cascader size="mini" v-model="city" :options="cityOptions" :props="{value:'id',label:'text'}" clearable @change="handleCityChange" ref="city"></el-cascader>
        </div>
        <div class="searchBox">
          <el-input size="mini" clearable placeholder="请输入您要的相关任务" v-model="searchForm.taskName" @change="handleTaskNameSearch(searchForm.taskName)">
            <template slot="prepend">
              <i class="el-icon-search"></i>
            </template>
            <template slot="append">
              <span @click="handleTaskNameSearch(searchForm.taskName)">搜索</span>
            </template>
          </el-input>
        </div>
        <div class="img_site"></div>
        <div class="hotWords">
          <span>热门搜索：</span>
          <span v-for="(item,index) in hotWords" :key="'hotWords'+index" :class="{active:searchForm.jobCateName == item.name}" @click="handleJobCateNameSearch(item.name)">{{item.name}}</span>
        </div>
      </div>
    </div>
    <div class="clearfix">
      <div class="left_section">
        <el-tabs v-model="tab" type="card" class="tabs_section" v-if="type.length">
          <el-tab-pane v-for="(item,index) in type" :key="'type'+index" :name="String(item.id)">
            <span slot="label"> {{item.label}} <i class="el-icon-arrow-up" v-if="tab == item.id"></i>
              <i class="el-icon-arrow-down" v-else></i>
            </span>
            <ul v-if="item.children">
              <li v-for="(item2,index2) in item.children" :key="'type-children'+index2" :class="{active:jobCategoryIds.includes(item2.id)}" @click="handleTypeChange(item2)">{{item2.label}}</li>
            </ul>
          </el-tab-pane>
        </el-tabs>
        <div class="filter_section" v-if="searchCity||searchForm.taskName||jobCategorySelected.length">
          <label>筛选条件：</label>
          <div class="box">
            <el-button type="primary" size="small" plain v-if="searchCity"> {{searchCity}} <i class="el-icon-close" @click="handleCityChange"></i>
            </el-button>
            <el-button type="primary" size="small" plain v-if="searchForm.taskName"> {{searchForm.taskName}} <i class="el-icon-close" @click="handleTaskNameSearch('')"></i>
            </el-button>
            <el-button type="primary" size="small" plain v-for="(item,index) in jobCategorySelected" :key="'jobCategorySelected'+index"> {{item.label}} <i class="el-icon-close" @click="handleTypeChange(item)"></i>
            </el-button>
            <el-button type="primary" size="small" @click="handleClear">清空</el-button>
          </div>
        </div>
        <div class="list_section">
          <template v-if="list.length">
            <task-item v-for="(item,index) in list" :key="'taskItem'+index" :item="item" class="item"></task-item>
          </template>
          <template v-else>
            <empty text="暂无任务"></empty>
          </template>
          <pagination :listLoading="listLoading" @getList="getList" @setLimit="searchForm.size = $event" @setPage="searchForm.current = $event" :currentPage="searchForm.current" :pageSize="searchForm.size" :total="searchForm.total"></pagination>
        </div>
      </div>
      <taskRight />
    </div>
  </div>
</template>

<script>
import empty from "@/components/empty/index.vue";
import taskItem from "./components/taskItem.vue";
import taskRight from "./components/taskRight.vue";
import pagination from "@/components/pagination/index.vue";
import api from "@/api/list";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";
export default {
  name: "taskCenterList",
  components: {
    taskItem,
    pagination,
    empty,
    taskRight,
  },
  data() {
    return {
      city: [],
      cityOptions: [],
      listLoading: false,
      searchCity: "",
      searchForm: {
        jobCategoryIds: "",
        taskName: this.$route.query.taskName,
        jobCateName: this.$route.query.jobCateName,
        cityId: "",
        current: 1,
        size: 10,
        total: 0,
      },
      tab: "",
      type: [],
      jobCategorySelected: [],
      jobCategoryIds: [],
      list: [],
      hotWords: [
        { id: 3, name: "市场推广" },
        { id: 4, name: "技术研发" },
        { id: 5, name: "企业咨询" },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    //获取首页传过来的城市
    let defaultCity = this.$route.query.city;
    if (defaultCity) {
      this.city = defaultCity.toString().split(",");
      this.city[0] = parseInt(this.city[0]);
      this.city[1] = parseInt(this.city[1]);
      this.searchForm.cityId = this.city[1];
    }

    //获取首页传过来的用工类型
    let defaultCate = this.$route.query.jobCategory;
    if (defaultCate) {
      defaultCate = JSON.parse(defaultCate);
      this.searchForm.jobCategoryIds = defaultCate.id;
      this.jobCategorySelected.push(defaultCate);
      this.jobCategoryIds.push(defaultCate.id);
    }

    //获取任务类型
    api.jobCategoryTree().then((res) => {
      if (res && res.data) {
        this.type = res.data;
        //获取已选状态
        let cId = this.jobCategorySelected.length
          ? this.jobCategorySelected[0].id
          : "";
        if (cId) {
          this.type.forEach((item, index) => {
            if (item.children) {
              item.children.forEach((item2, index2) => {
                if (item2.id == cId) {
                  this.tab = String(item.id);
                }
              });
            }
          });
        } else {
          this.tab = this.$route.query.tab
            ? this.$route.query.tab
            : String(res.data[0].id);
        }
      }
    });

    //获取城市列表
    api.getAllCity().then((res) => {
      this.cityOptions = res.data;
      if (this.city && this.city.length) {
        setTimeout(() => {
          //设置城市选择状态文字
          this.searchCity = this.$refs["city"]
            .getCheckedNodes()[0]
            .pathLabels.join("/");
        }, 1000);
      }
    });

    //获取任务列表
    this.getList();
  },
  methods: {
    getList() {
      //获取任务列表
      if (getToken() !== "undefined" && getToken()) {
        // api.tasksCenterList(this.searchForm).then((res) => {
        api.tasksCenterListNew(this.searchForm).then((res) => {
          if (res && res.data) {
            if (res.status == "200") {
              this.list = res.data.records;
              this.searchForm.total = res.data.total;
            }
          }
        });
      } else {
        api.unLoginTaskCenterList(this.searchForm).then((res) => {
          if (res && res.data) {
            if (res.status == "200") {
              this.list = res.data.records;
              this.searchForm.total = res.data.total;
            }
          }
        });
      }
    },
    handleCityChange(arr) {
      if (arr && arr.length) {
        this.searchCity = this.$refs["city"]
          .getCheckedNodes()[0]
          .pathLabels.join("/");
        this.searchForm.cityId = arr[1];
      } else {
        this.searchCity = "";
        this.searchForm.cityId = "";
        this.city = [];
      }
      this.searchForm.current = 1;
      this.getList();
    },
    handleTypeChange(item2) {
      let index = this.jobCategorySelected.findIndex((item) => {
        return item.id == item2.id;
      });
      if (index == -1) {
        this.jobCategorySelected.push(item2);
      } else {
        this.jobCategorySelected.splice(index, 1);
      }

      this.jobCategoryIds = this.jobCategorySelected.map((v) => {
        return v.id;
      });

      this.searchForm.jobCategoryIds = this.jobCategoryIds.toString(
        this.jobCategoryIds
      );

      this.searchForm.current = 1;
      this.getList();
    },
    handleTaskNameSearch(str) {
      this.searchForm.taskName = str;
      this.searchForm.current = 1;
      this.getList();
    },
    handleJobCateNameSearch(str) {
      this.searchForm.jobCateName = str;
      this.searchForm.current = 1;
      this.getList();
    },
    handleClear() {
      this.searchCity = "";
      this.city = [];
      this.searchForm.taskName = "";
      this.searchForm.jobCateName = "";
      this.searchForm.jobCategoryIds = "";
      this.searchForm.cityId = "";
      this.jobCategorySelected = [];
      this.jobCategoryIds = [];
      this.searchForm.current = 1;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.tabs_section {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 20px;
  i {
    margin-left: 10px;
  }

  ul {
    padding-left: 12px;
    li {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: #999;
      margin-right: 12px;
      line-height: 30px;
      cursor: pointer;

      &:hover,
      &.active {
        color: #8493fa;
      }
    }
  }
}

.filter_section {
  font-size: 14px;
  color: #999;
  border-radius: 3px;
  padding: 12px;
  background: #fff;
  margin-bottom: 10px;
  label {
    display: inline-block;
    vertical-align: text-top;
    line-height: 28px;
  }

  .box {
    display: inline-block;
    vertical-align: text-top;
    width: 90%;
  }

  /deep/ .el-button {
    position: relative;
    margin: 0 8px 8px 0;

    &.el-button--primary {
      padding-right: 30px;

      &:last-child {
        padding-right: 15px;
      }
    }

    i {
      position: absolute;
      right: 6px;
    }
  }
}

.clearfix {
  width: 1210px;
  margin: 0 auto;
}

.left_section {
  width: 905px;
  float: left;
}
.list_section {
  border-radius: 3px;
  background: #fff;
}
.tasksListPage {
  .box {
    background: #fff;
    border-radius: 2px;
  }
  .typeList {
    font-size: 14px;
    line-height: 20px;

    span {
      display: inline-block;
      vertical-align: middle;
      color: #666;
      margin-right: 15px;
      margin-bottom: 12px;
      cursor: pointer;

      &:first-child {
        color: #999;
        margin-right: 10px;
      }

      &.active {
        color: #f48960;
      }
    }
  }
}
</style>