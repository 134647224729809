<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 13:38:52
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-08 16:46:38
-->
<template>
  <a href="javascript:void(0);" class="item" @click="handleDetail(item)">
    <div class="main">
      <div class="line_1">
        <span class="name text-ellipsis">{{item.ecompanyJobCategoryName}}</span>
        <span class="city text-ellipsis">{{item.cityName?item.cityName:"不限"}}</span>
      </div>
      <div class="line_2 text-ellipsis" v-html="item.taskName"></div>
      <div class="line_3 text-ellipsis">
        {{item.taskMoney=="费用面议"?"":item.amount}}
        <span
          :class="{only:item.amount==undefined||item.amount.length == 0||item.taskMoney == '费用面议'}"
        >
          <template v-if="item.taskMoney == '费用面议'">费用面议</template>
          <template v-else-if="item.taskMoney == '固定价格'">/元</template>
          <template v-else-if="item.taskMoney == '按月'">元/月</template>
          <template v-else-if="item.taskMoney == '按周'">元/周</template>
          <template v-else-if="item.taskMoney == '按天'">元/天</template>
          <template v-else-if="item.taskMoney == '按时'">元/小时</template>
        </span>
      </div>
    </div>
    <div class="right">
      <template v-if="item.isApply">
        <el-button type="warning" v-if="item.employeeTaskStatus == 'applying'">申请中</el-button>
        <el-button type="primary" v-else-if="item.employeeTaskStatus == 'doing'">进行中</el-button>
        <el-button
          type="success"
          v-else-if="item.employeeTaskStatus == 'finish'|| item.employeeTaskStatus == 'nopass' || item.employeeTaskStatus == 'cancelled'"
        >申请</el-button>
      </template>
      <template v-else>
        <el-button
          type="success"
          v-if="item.applyStatus == 'applying' && item.employeeTaskStatus != 'nopass'"
        >申请</el-button>
        <el-button type="info" v-if="item.employeeTaskStatus == 'nopass'">不可申请</el-button>
        <el-button
          type="info"
          v-if="item.applyStatus == 'stop' && item.employeeTaskStatus != 'nopass'"
        >已截止</el-button>
      </template>
      <p>发布时间:{{sliceData(item.publishDate)}}</p>
    </div>
    <div class="type end" v-if="item.applyStatus == 'stop'"></div>
  </a>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    timeLeftCount(data) {
      return new Date(data.replace(/-/g, "/")).getTime() - new Date().getTime();
    },
    handleDetail(item) {
      // this.$router.push({ path: "/tasksDetail", query: { taskId: item.id } });
        if(item.isApply){
           if (item.employeeTaskStatus == "applying" || item.employeeTaskStatus == "doing") {
              this.$router.push({
                path: "/userCenter/tasksDetails",
                query: {taskId: item.id},

            });
            }
         } else {
        this.$router.push({ path: "/tasksDetail", query: { taskId: item.id } });
      }
    },
    sliceData(data) {
      if (data && data.length == 10) {
        return data.slice(5);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  position: relative;
  display: block;
  background: #fff;
  line-height: 36px;
  padding: 20px 28px;
  border-bottom: 1px solid #f5f5f5;
  .main {
    display: inline-block;
    width: 80%;
    .line_1 {
      margin-bottom: -8px;
      .name {
        display: inline-block;
        vertical-align: text-bottom;
        font-size: 22px;
        color: #333;
        margin-right: 20px;
        max-width: 10em;
      }

      .city {
        display: inline-block;
        vertical-align: text-bottom;
        color: #666666;
        font-size: 18px;
        max-width: 10em;
      }
    }

    .line_2 {
      font-size: 16px;
      height: 36px;
      margin-bottom: 4px;
      color: #999;
      cursor: pointer;

      /deep/ p {
        margin: 0;
      }
    }

    .line_3 {
      color: #f2641e;
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      margin-right: 12px;
      span {
        font-size: 14px;

        &.only {
          font-size: 22px;
        }
      }
    }
  }

  .right {
    position: absolute;
    right: 30px;
    top: 20px;
    /deep/ .el-button {
      display: block;
      width: 110px;
      padding: 8px;
      font-size: 18px;
      border-radius: 2px;
      margin: 0 auto;
    }

    p {
      font-size: 14px;
      color: #999;
      text-align: right;
      margin-top: 40px;
    }
  }

  .type {
    position: absolute;
    left: 60%;
    top: 50%;
    margin-top: -46px;
    display: block;
    width: 92px;
    height: 92px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    &.end {
      background: url(./assets/end.png);
    }
  }
}
</style>
